import React from 'react'
import Layout from '../../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>この度はお問い合わせくださいまして誠にありがとうございます。</p>
          <p>送信いただきました内容を確認の上、担当者より二営業日以内にご連絡差し上げます。</p>
          
        </div>
      </div>
    </section>
  </Layout>
)
